<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <Filter @submit="getDataTable()" ref="filter"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="trx_date" header="Transaction Date">
                        <template #body="slotProps">
                            {{slotProps.data.trx_date}}
                        </template>
                    </Column>
                    <Column field="area_name" header="Area">
                        <template #body="slotProps">
                            {{slotProps.data.area_name}}
                        </template>
                    </Column>
                    <Column field="region_name" header="Region">
                        <template #body="slotProps">
                            {{slotProps.data.region_name}}
                        </template>
                    </Column>
                    <Column field="branch_name" header="Branch">
                        <template #body="slotProps">
                            {{slotProps.data.branch_name}}
                        </template>
                    </Column>
                    <Column field="subbranch_name" header="Sub Branch">
                        <template #body="slotProps">
                            {{slotProps.data.subbranch_name}}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.username}}
                        </template>
                    </Column>
                    <Column field="outlet_name" header="Outlet Name">
                        <template #body="slotProps">
                            {{slotProps.data.outlet_name}}
                        </template>
                    </Column>
                    <Column field="outlet_owner" header="Outlet Owner">
                        <template #body="slotProps">
                            {{slotProps.data.outlet_owner}}
                        </template>
                    </Column>
                    <Column field="outlet_phone" header="Outlet Phone">
                        <template #body="slotProps">
                            {{slotProps.data.outlet_phone}}
                        </template>
                    </Column>
                    <Column field="display_block_shelving" header="Block Shelving">
                        <template #body="slotProps">
                            {{slotProps.data.display_block_shelving}}
                        </template>
                    </Column>
                    <Column field="display_type_a" header="Display Type A">
                        <template #body="slotProps">
                            {{slotProps.data.display_type_a}}
                        </template>
                    </Column>
                    <Column field="display_type_b" header="Display Type B">
                        <template #body="slotProps">
                            {{slotProps.data.display_type_b}}
                        </template>
                    </Column>
                    <Column field="display_non_sewa" header="Non Sewa">
                        <template #body="slotProps">
                            {{slotProps.data.display_non_sewa}}
                        </template>
                    </Column>
                    <Column field="photo_display" header="Photo Display">
                        <template #body="slotProps">
                            <Image :src="slotProps.data.photo_display" :alt="slotProps.data.photo_display" width="100" preview />
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Filter from '../../components/report/mitra-availability/Filter.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Filter': Filter,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/mitra-availability',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "area_id" : this.$refs.filter.filters.area_id,
                    "region_id" : this.$refs.filter.filters.region_id,
                    "branch_id" : this.$refs.filter.filters.branch_id,
                    "subbranch_id" : this.$refs.filter.filters.subbranch_id,
                    "start_date" : this.$refs.filter.period_start_label,
                    "end_date" : this.$refs.filter.period_end_label,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
              this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(
              this.$refs.filter.period_start_label !== null && 
              this.$refs.filter.period_end_label !== null && 
              this.$refs.filter.period_start_label !== 'Invalid date' && 
              this.$refs.filter.period_end_label !== 'Invalid date'
            ){  

                if(ext == 'xlsx'){
                    this.loadingExcel=true;
                }else if(ext == 'csv'){
                    this.loadingCsv=true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/mitra-availability/export',
                    responseType: 'blob',
                    params: {
                        "ext" : ext,
                        "area_id" : this.$refs.filter.filters.area_id,
                        "region_id" : this.$refs.filter.filters.region_id,
                        "branch_id" : this.$refs.filter.filters.branch_id,
                        "subbranch_id" : this.$refs.filter.filters.subbranch_id,
                        "start_date" : this.$refs.filter.period_start_label,
                        "end_date" : this.$refs.filter.period_end_label,
                    }
                })
                .then(response => {
                    let fileUrl = window.URL.createObjectURL(response.data);
                    let fileLink = document.createElement('a');

                    fileLink.href = fileUrl;

                    fileLink.setAttribute('download', 'Report Mitra Availability Export.' + ext);
                    
                    document.body.appendChild(fileLink)

                    fileLink.click();
                    
                    if(ext == 'xlsx'){
                        this.loadingExcel=false;
                    }else if(ext == 'csv'){
                        this.loadingCsv=false;
                    }

                })
                .catch((err) => {
                    console.log(err);
                    
                    if(ext == 'xlsx'){
                        this.loadingExcel=false;
                    }else if(ext == 'csv'){
                        this.loadingCsv=false;
                    }
                });

            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        },
    }
}
</script>
